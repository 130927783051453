<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<el-tabs v-model="activeName" type="border-card"
				style="height: 100%; box-sizing: border-box; overflow: auto;">
				<el-tab-pane label="社区" name="community">
					<div class="tree">
						<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps"
							:default-expanded-keys="[1]" :default-checked-keys="[1]" :highlight-current="true"
							@node-click="nodeClick" ref="tree">
							<span class="my-custom" slot-scope="{ node, data }" style="width: 100%;height: 100%;">
								<span style="width: 100%;height: 100%;line-height: 32px;">{{ data.name }}</span>
							</span>
						</el-tree>
					</div>
				</el-tab-pane>
				<el-tab-pane label="标签" name="label">
					<el-tree :expand-on-click-node="false" accordion :load="loadNode" @node-expand="expandClick"
						@node-click="lableTreeNodeClick" lazy :data="lableTreeData" :highlight-current="true"
						:props="labelTreeProps"></el-tree>
				</el-tab-pane>
				<el-tab-pane label="网格" name="net">
					<el-tree :expand-on-click-node="false" accordion :load="netLoadNode" @node-click="netTreeNodeClick"
						lazy :data="netTreeData" :highlight-current="true" :props="netTreeProps"></el-tree>
				</el-tab-pane>
			</el-tabs>

		</div>
		<div class="right">

			<div class="transformer">
				<!-- <div class="topbox">
					<div class="itembox">
						<div class="itemleft">
							<div style="margin-right: 10px;">
								<img src="@/assets/imgs/person/male.png" alt="">
								<p>男 <span style="color: #34a5df;">10人</span></p>
							</div>
							<div style="margin-left: 10px;">
								<img src="@/assets/imgs/person/female.png" alt="">
								<p>女 <span style="color: #e56a9c;">10人</span></p>
							</div>
						</div>
						<div class="itemright">
							<div style="border-right: 1px solid #e3e3e3;">
								<p class="num"><span>20</span>人</p>
								<p class="name">户籍人口</p>
							</div>
							<div>
								<p class="num"><span>20</span>人</p>
								<p class="name">非户籍人口</p>
							</div>
						</div>
					</div>
					<div class="itembox">
						<div class="title">年龄段</div>
						<div class="box">
							<div v-if="agegroup.height1 < 20" class="zhuzi">
								<div class="percent percent1" :style="'height:' + agegroup.height1 + 'px;'"></div>
								<p :style="'bottom:' + agegroup.height1 + 'px;'">{{agegroup.person1}}</p>
							</div>
							<div v-else class="zhuzi">
								<div class="percent percent1" :style="'height:' + agegroup.height1 + 'px;'">
									<p>{{agegroup.person1}}</p>
								</div>
							</div>
							<img src="@/assets/imgs/person/person1.png" alt="">
							<p>儿童（≤6岁）</p>
						</div>
						<div class="box">
							<div v-if="agegroup.height2 < 20" class="zhuzi">
								<div class="percent percent2" :style="'height:' + agegroup.height2 + 'px;'"></div>
								<p :style="'bottom:' + agegroup.height2 + 'px;'">{{agegroup.person2}}</p>
							</div>
							<div v-else class="zhuzi">
								<div class="percent percent2" :style="'height:' + agegroup.height2 + 'px;'">
									<p>{{agegroup.person2}}</p>
								</div>
							</div>
							<img src="@/assets/imgs/person/person2.png" alt="">
							<p>少年（7-17岁）</p>
						</div>
						<div class="box">
							<div v-if="agegroup.height3 < 20" class="zhuzi">
								<div class="percent percent3" :style="'height:' + agegroup.height3 + 'px;'"></div>
								<p :style="'bottom:' + agegroup.height3 + 'px;'">{{agegroup.person3}}</p>
							</div>
							<div v-else class="zhuzi">
								<div class="percent percent3" :style="'height:' + agegroup.height3 + 'px;'">
									<p>{{agegroup.person3}}</p>
								</div>
							</div>
							<img src="@/assets/imgs/person/person3.png" alt="">
							<p>青年（18-40岁）</p>
						</div>
						<div class="box">
							<div v-if="agegroup.height4 < 20" class="zhuzi">
								<div class="percent percent4" :style="'height:' + agegroup.height4 + 'px;'"></div>
								<p :style="'bottom:' + agegroup.height4 + 'px;'">{{agegroup.person4}}</p>
							</div>
							<div v-else class="zhuzi">
								<div class="percent percent4" :style="'height:' + agegroup.height4 + 'px;'">
									<p>{{agegroup.person4}}</p>
								</div>
							</div>
							<img src="@/assets/imgs/person/person4.png" alt="">
							<p>中年（41-65岁）</p>
						</div>
						<div class="box">
							<div v-if="agegroup.height5 < 20" class="zhuzi">
								<div class="percent percent5" :style="'height:' + agegroup.height5 + 'px;'"></div>
								<p :style="'bottom:' + agegroup.height5 + 'px;'">{{agegroup.person5}}</p>
							</div>
							<div v-else class="zhuzi">
								<div class="percent percent5" :style="'height:' + agegroup.height5 + 'px;'">
									<p>{{agegroup.person5}}</p>
								</div>
							</div>
							<img src="@/assets/imgs/person/person5.png" alt="">
							<p>老年（≥66岁）</p>
						</div>
					</div>
				</div> -->
				<div class="clearfix electric-select" style="margin: 0;">
					<el-form ref="ruleForm" label-width="auto" :model="chaxun" class="demo-form-inline" :inline="true">
						<el-form-item label="姓名：">
							<el-input class="shuru" v-model="chaxun.name"></el-input>
						</el-form-item>
						<el-form-item label="身份证号：">
							<el-input class="shuru" v-model="chaxun.code"></el-input>
						</el-form-item>
						<el-form-item label="户籍地址：">
							<el-input class="shuru" v-model="chaxun.censusaddrname"></el-input>
						</el-form-item>
						<el-form-item label="居住地址：">
							<el-input class="shuru" v-model="chaxun.houseareaname"></el-input>
						</el-form-item>
						<el-form-item label="系统房屋地址：">
							<el-input class="shuru" v-model="chaxun.syshousearea"></el-input>
						</el-form-item>
						<el-form-item label="户口性质：">
							<el-select class="shuru" v-model="chaxun.incommunity" clearable placeholder="户口性质">
								<el-option label="户籍人口" :value="-1"></el-option>
								<el-option label="户在人在" :value="0"></el-option>
								<el-option label="户在人不在" :value="3"></el-option>
								<el-option label="户在人不经常在" :value="1"></el-option>
								<el-option label="流动人口" :value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="标签：">
							<el-cascader class="shuru" v-model="chaxun.label" :options="labels" :show-all-levels="false"
								:props="{ checkStrictly: true }" clearable></el-cascader>
						</el-form-item>
						<!-- <el-form-item label="社区：">
							<el-select clearable v-model="chaxun.shequ" placeholder="请选择社区">
								<el-option v-for="item in list" :key="item.name" :label="item.name"
									:value="item.levelcode"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label="学历：">
							<el-input class="shuru" v-model="chaxun.eduid"></el-input>
						</el-form-item>
						<el-form-item label="性别：">
							<el-select v-model="chaxun.sex" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="电话号码：">
							<el-input class="shuru" v-model="chaxun.tel"></el-input>
						</el-form-item>
						<el-form-item prop="data1" class="data">
							<el-dropdown @command="handleCommand" style="margin:0 8px;">
								<span class="el-dropdown-link">
									出生年月<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="出生年月">出生年月</el-dropdown-item>
									<el-dropdown-item command="出生年月区间">出生年月区间</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<el-form-item>
								<el-date-picker type="month" style="text-align: center;width: 100%;" placeholder="出生年月"
									v-model="chaxun.date1" value-format="yyyy-MM"
									v-if="chaxun.command=='出生年月'"></el-date-picker>
								<el-date-picker style="text-align: center;" v-model="chaxun.date2" type="daterange"
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									value-format="yyyy-MM-dd" v-if="chaxun.command=='出生年月区间'">
								</el-date-picker>
							</el-form-item>
						</el-form-item>
						<!-- <el-form-item class="data" style="margin-bottom: 10px;">
							<el-dropdown @command="handleCommandDz">
								<span class="el-dropdown-link">
									{{chaxun.commandDz}}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="平台地址">平台地址</el-dropdown-item>
									<el-dropdown-item command="统一平台地址">统一平台地址</el-dropdown-item>
									<el-dropdown-item command="户籍地址">户籍地址</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<el-form-item>
								<el-input class="shuru" v-model="chaxun.local"></el-input>
							</el-form-item>
						</el-form-item> -->
						<el-form-item>
							<el-button type="primary" @click="onsearch()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button type="primary" @click="importPersons()"><i
									class="el-icon-upload2"></i>导入</el-button>
							<el-button type="primary" @click="handleExport()"><i
									class="el-icon-download"></i>导出</el-button>

							<el-button type="primary" @click="personFlow()"><i
									class="el-icon-tickets"></i>人员流动流水</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div v-loading="loading2">
					<div class="totaldata">共{{ fenye.totalnum }}条数据</div>
					<el-table :data="tableData" border style="width: 100%;">
						<el-table-column type="index" width="50" align="center" height show-overflow-tooltip>
							<template scope="scope"><span>{{scope.$index+(currentPage - 1) * fenye.pagesize + 1}}
								</span></template>
						</el-table-column>
						<el-table-column prop="personname" label="姓名" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="person_sex" label="性别" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="eduid" label="学历" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="personphone" label="联系电话" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="communityname" label="社区" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="regionname" label="小区" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="censusaddr" label="户籍地址" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column label="居住地址" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								{{scope.row.housearea?scope.row.housearea:scope.row.houseareatemp}}
							</template>
						</el-table-column>
						<el-table-column prop="syshousearea" label="系统房屋地址" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="incommunity" label="户口性质" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								{{ getTextOfVal(scope.row.incommunity) }}
							</template>
						</el-table-column>
						<!-- <el-table-column
						  prop="idcard"
						  label="身份证号"
						  width="200">
						</el-table-column>
						<el-table-column
						  prop="keyword"
						  label="关键字">
						</el-table-column> -->
						<el-table-column label="操作" width="390" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								<el-button type="primary" size="small"
									@click="lookClick(scope.row.queryid,'ziji')">查看</el-button>
								<el-button type="primary" size="small"
									@click="lookHouseInManClick(scope.row.house_code)">同住人员信息</el-button>
								<el-button type="primary" size="small"
									@click="relation(scope.row.house_code)">同住人员关系图</el-button>
								<el-button @click="deleteFn(scope.row)" type="danger" size="mini">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-table :data="tableData2" border style="width: 100%;" ref="Table1" id="Table1" v-show="false">
						<el-table-column type="index" width="50" align="center" height show-overflow-tooltip>
							<template scope="scope"><span>{{scope.$index+(currentPage - 1) * fenye.pagesize + 1}}
								</span></template>
						</el-table-column>
						<el-table-column prop="personname" label="姓名" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="person_sex" label="性别" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="personphone" label="联系电话" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="communityname" label="社区" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="regionname" label="小区" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="censusaddr" label="户籍地址" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column label="居住地址" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								{{scope.row.housearea?scope.row.housearea:scope.row.houseareatemp}}
							</template>
						</el-table-column>
						<el-table-column prop="deldesc" label="居住情况" align="center" height show-overflow-tooltip>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="查看详情" width='75%' :visible.sync="xiangqing" :close-on-click-modal="false"
			:before-close="handleClose">
			<div class="create" style="overflow: auto;">
				<div class="toubu">
					<p>基础信息</p>
					<el-button v-if="leixing == 'ziji'" type="primary" @click="xiugai(jichu.mulpersoninfp)">修改信息</el-button>
				</div>

				<div class="produce">
					<div>姓名：{{jichu.mulpersoninfp.personname}}</div>
					<div>性别：{{jichu.mulpersoninfp.person_sex}}</div>
					<div>学历：{{jichu.mulpersoninfp.eduid}}</div>
					<div>身份证：{{jichu.mulpersoninfp.personcard}}</div>
					<div>现居地：{{jichu.mulpersoninfp.housearea}}</div>
					<div>户籍地：{{jichu.mulpersoninfp.censusaddr}}</div>
					<div>电话：{{jichu.mulpersoninfp.personphone}}</div>
					
					<div>民族：{{jichu.mulpersoninfp.personnation}}</div>
					<div>婚姻状况：{{jichu.mulpersoninfp.marriage}}</div>
					<div>政治面貌：{{jichu.mulpersoninfp.party}}</div>
					<div>宗教信仰：{{jichu.mulpersoninfp.faith}}</div>
					<div>与房主关系：{{jichu.mulpersoninfp.housemasterrelation}}</div>
					<div>与户主关系：{{jichu.mulpersoninfp.masterrelation}}</div>

					<div>在住情况：{{jichu.mulpersoninfp.deldesc}}</div>
					<div>社区名：{{jichu.mulpersoninfp.communityname}}</div>
					<div>小区名/组名：{{jichu.mulpersoninfp.regionname}}</div>
					<div>户口性质：{{ getTextOfVal(jichu.mulpersoninfp.incommunity) }}</div>
					<div></div>
					<!-- <div>是否为党员：{{jichu.mulpersoninfp.party}}</div> -->
					<div class="biaoqian" v-if="jichu.labelinfo && jichu.labelinfo.length > 0">
						<ul>
							<li v-for="blab in jichu.labelinfo" :key="blab.label_item_id">{{ blab.label_name}}</li>
						</ul>
					</div>
				</div>

			</div>
		</el-dialog>
		<el-dialog title="修改信息" width='650px' :visible.sync="xiangqing10" :close-on-click-modal="false"
			:before-close="handleClose10">
			<div class="xinzeng" v-loading="loading10" element-loading-text="新增中"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
				<el-form ref="form" :model="list10" label-width="100px">
					<div>
						<el-form-item label="姓名">
							<el-input v-model="list10.personname" placeholder="姓名"></el-input>
						</el-form-item>
						<el-form-item label="身份证">
							<el-input v-model="list10.personcard" placeholder="请输入身份证"></el-input>
						</el-form-item>
						<el-form-item label="电话">
							<el-input v-model="list10.personphone" placeholder="请输入电话"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit10">修改</el-button>
							<el-button @click="close10">取消</el-button>
						</el-form-item>
					</div>

				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="同住人员关系图" width='1000px' :visible.sync="xiangqing2" :close-on-click-modal="false"
			:before-close="handleClose2">
			<div style="position: relative;">
				<div class="box" id="box" style="height: 600px;position: relative;">

				</div>
				<div class="tishi">
					<div class="type1">颜色一致代表户号一致</div>
					<div class="type2 circle">户籍人口</div>
					<div class="type2 rect">非户籍人口</div>
					<div class="type2 triangle">租客</div>
				</div>
			</div>

		</el-dialog>

		<el-dialog title="同住人员信息" width='70%' :visible.sync="xiangqingHouse" :close-on-click-modal="false"
			:before-close="handleCloseHouse">
			<div class="create" style="overflow: auto;">
				<div v-loading="loadingHouse" v-if="housemsg" class="detailtable">
					<p>房屋信息</p>
					<div class="labelname">住址</div>
					<div style="width: 26%;">{{housemsg.housename}}</div>
					<div class="labelname">所在社区</div>
					<div style="width: 14%;">{{housemsg.name}}</div>
					<div class="labelname">房屋性质</div>
					<div style="width: 14%;">{{housemsg.house_type}}</div>
					<div class="labelname">住房种类</div>
					<div style="width: 14%;">{{housemsg.house_type_flag}}</div>
					<div class="labelname">房屋面积</div>
					<div style="width: 26%;">{{housemsg.area}}平方米</div>
					<div class="labelname">机动车数量</div>
					<div style="width: 14%;">{{housemsg.carnum}}</div>
					<div class="labelname">电动车数量</div>
					<div style="width: 14%;">{{housemsg.bikenum}}</div>
					<div class="labelname">宠物类型</div>
					<div style="width: 14%;">{{housemsg.pettype}}</div>
				</div>
				<div v-loading="loadingHouse" v-else class="detailtable">
					<p>房屋信息</p>
					<div style="width: 100%; text-align: left;">暂无</div>
				</div>

				<div style="padding: 20px; display: flex; justify-content: flex-end;">
					<el-radio-group v-model="showType">
						<el-radio :label="1">户型展示</el-radio>
						<el-radio :label="2">表格展示</el-radio>
						<el-radio :label="3">关系图展示</el-radio>
					</el-radio-group>
				</div>

				<div class="personsbox" v-if="showType == 3">
					<div class="huzhuinfo">
						<div class="fz_item" v-if="masterlist[0]">
							<div class="head">
								<div class="iconsex">
									<img v-if="masterlist[0].person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
									<img v-else src="@/assets/imgs/man.png" alt="男">
								</div>
								<img src="@/assets/imgs/fangzhu.png" alt="房主">
							</div>
							<ul>
								<li><span>姓名：</span>{{ masterlist[0].mastername}}</li>
								<li><span>电话：</span>{{ masterlist[0].phone}}</li>
								<li><span>身份证号：</span>{{ masterlist[0].personcard }}</li>
							</ul>
						</div>
						<div class="fz_item" v-else>
							<div class="head">
								<i class="el-icon-user-solid"></i>
								<img src="@/assets/imgs/fangzhu.png" alt="房主">
							</div>
							<div>暂无房主信息</div>
						</div>
					</div>

					<div class="zhuhuinfo">
						<div class="zh_item" v-for="item in personlist" :key="item.personcard">
							<div class="head">
								<div class="iconsex">
									<img v-if="item.person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
									<img v-else src="@/assets/imgs/man.png" alt="男">
								</div>
								<p v-if="item.housemasterrelation == '租客'">流动人口</p>
								<p v-else>常住人口</p>
							</div>
							<ul>
								<li><span>姓名：</span>{{ item.personname}}</li>
								<li><span>电话：</span>{{ item.personphone}}</li>
								<li><span>身份证号：</span>{{ item.personcard }}</li>
								<li><span>与房主关系：</span> {{ item.housemasterrelation }}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="tabletype" v-if="showType == 2">
					<div v-loading="loadingHouse" class="detailtable" v-for="(item,index) in masterlist" :key="index">
						<p>房主基本情况</p>
						<div class="labelname">姓名</div>
						<div style="width: 13.4%;">{{item.mastername}}</div>
						<div class="labelname">手机号</div>
						<div style="width: 34.8%;">{{item.phone}}</div>
						<div class="labelname">身份证号</div>
						<div style="width: 27.8%;">{{item.personcard}}</div>
						<div class="labelname">居住地址</div>
						<div style="width: 92%;" class="biaoshi">{{item.address}}</div>
					</div>
					<div v-loading="loadingHouse" class="detailtable" v-for="(item,index) in personlist" :key="index">
						<p>居民基本情况</p>
						<div class="labelname">姓名</div>
						<div style="width: 17%;">{{item.personname}}</div>
						<div class="labelname">民族</div>
						<div style="width: 17%;">{{item.personnation}}</div>
						<div class="labelname">户籍地址</div>
						<div style="width: 42%;">{{item.censusaddr}}</div>
						<div class="labelname">性别</div>
						<div style="width: 17%;">{{item.person_sex}}</div>
						<div class="labelname">学历</div>
						<div style="width: 17%;">{{item.eduid}}</div>

						<div class="labelname">身份证号</div>
						<div style="width: 17%;">{{item.personcard}}</div>
						<div class="labelname">出生日期</div>
						<div style="width: 17%;">{{item.personbirthday}}</div>
						<div class="labelname">婚姻状况</div>
						<div style="width: 17%;">{{item.marriage}}</div>
						<div class="labelname">宗教信仰</div>
						<div style="width: 17%;">{{item.faith}}</div>
						<div class="labelname">联系方式</div>
						<div style="width: 17%;">{{item.personphone}}</div>
						<div class="labelname">人员类别</div>
						<div style="width: 17%;">
							<span>{{ getTextOfVal(item.incommunity) }}</span>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;"
							v-if="item.incommunity == 0">
							<div class="labelname">与房主关系</div>
							<div style="width: 17%;">{{item.housemasterrelation}}</div>
							<div class="labelname">户号</div>
							<div style="width: 17%;">{{item.accountcode}}</div>
							<div class="labelname">与户主关系</div>
							<div style="width: 17%;">{{item.accountrelation}}</div>
							<div class="labelname">是否有居住证</div>
							<div style="width: 17%;">{{item.temporarycard}}</div>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;"
							v-if="item.incommunity == 1">
							<div class="labelname">与房主关系</div>
							<div style="width: 42%;">{{item.housemasterrelation}}</div>
							<div class="labelname">是否有居住证</div>
							<div style="width: 42%;">{{item.temporarycard}}</div>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;"
							v-if="item.incommunity == 2">
							<div class="labelname">户号</div>
							<div style="width: 42%;">{{item.accountcode}}</div>
							<div class="labelname">与户主关系</div>
							<div style="width: 42%;">{{item.accountrelation}}</div>
						</div>
						<div class="biaoqian" style="width: 100%;" v-if="item.labellist.length > 0">
							<ul>
								<li v-for="blab in item.labellist" :key="blab.label_item_id">{{ blab.label_name}}</li>
							</ul>
						</div>
					</div>

				</div>
				<div class="tabletype" v-if="showType == 1">
					<div class="alldata">
						<div class="hang one">
							<div>全部{{showpicpage.personnums}}人</div>
							<div>
								户籍人口{{showpicpage.huzairenbujingchangzai + showpicpage.huzairenbuzai + showpicpage.huzairenzai}}人
								户数{{showpicpage.hushu}}户</div>
							<div>流动人口{{showpicpage.liudongrenkou}}人 户数{{showpicpage.liudonghushu}}户</div>
							<div v-if="housemsg.roomallnums && housemsg.roomallnums>0">总户数{{housemsg.roomallnums}}户
								空余数{{housemsg.roomallnums - showpicpage.liudonghushu}}户</div>
						</div>
						<div class="hang two">
							<div @click="xuanze(0)" :class="selectleixing === 0?'selected':''">
								人在户在{{showpicpage.huzairenzai}}人</div>
							<div @click="xuanze(3)" :class="selectleixing === 3?'selected':''">
								户在人不在{{showpicpage.huzairenbuzai}}人</div>
							<div @click="xuanze(1)" :class="selectleixing === 1?'selected':''">
								户在人不经常在{{showpicpage.huzairenbujingchangzai}}人</div>
							<div @click="xuanze(2)" :class="selectleixing === 2?'selected':''">
								流动人口{{showpicpage.liudongrenkou}}人</div>
						</div>
						<div class="hang three">
							<div v-for="(item,index) in showpicpage.housepersonlable"
								:class="selectTagName === item.label_name?'highblue':''" :key="index"
								@click="highTag(item.label_name)">{{item.label_name}} {{item.labelnums}}人</div>
						</div>
					</div>
					<div class="allpeople">
						<div class="yihu" v-for="(item,index) in showpicpage.personinfolist" :key="index">
							<div class="title"> <img src="../../assets/imgs/houseicon.png" alt=""> <p>{{ item[0]? item[0].accountcode : '/'}}</p></div>
							<div class="people" v-for="(item2,index2) in item" :key="index+index2">
								<div class="head" @click="lookClick(item2.id)">
									<div :class="item2.incommunity === selectleixing?'img lanse':'img huise'">
										<img v-if="item2.person_sex == '男' && item2.age < 18" src="../../assets/imgs/person/son.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age < 18" src="../../assets/imgs/person/girl.png" alt="">
										<img v-if="item2.person_sex == '男' && item2.age >= 18 && item2.age < 60" src="../../assets/imgs/person/father.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age >= 18 && item2.age < 60" src="../../assets/imgs/person/mother.png" alt="">
										<img v-if="item2.person_sex == '男' && item2.age >= 60" src="../../assets/imgs/person/grandfather.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age >= 60" src="../../assets/imgs/person/grandmother.png" alt="">
									</div>
									<p>{{item2.personname}}</p>
									<p v-if="item2.age" style="font-size:12px; line-height: 1em;">（{{ item2.age }}岁）</p>
									<div class="tag" v-for="(item3,index3) in item2.lablelist" :key="index+index2+index3"  :class="item3.label_name === selectTagName? 'blue': ''">{{item3.label_name}}</div>
								</div>
								
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog title="导入人员" :visible.sync="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="模版" :label-width="formLabelWidth">
					<el-button size="small" type="primary" @click="downLoadModel">下载模版</el-button>
				</el-form-item>
				<el-form-item label="上传导入文件" :label-width="formLabelWidth">
					<el-upload ref="upload" :limit="1" action="" accept=".xls,.xlsx" :on-exceed="handleExceed"
						:on-change="handleChange" :auto-upload="false">
						<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancleImport">取 消</el-button>
				<el-button type="primary" @click="sureImport">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		basePersonlist,
		getUserLevelcode,
		showlabel,
		searchPersonNumsTree,
		personInfo,
		houseinfolist,
		labelTree,
		netTree,
		searchPersonNumsTreeByItem,
		searchDanyuanTreeByItem,
		searchBuildTreeByItem,
		personDownexcel,
		personImportData,
		indexPeronRelation,
		ExportbasePersonlist,
		LableQuery,
		personDelete,
		PersonUpdate
	} from '@/api/shaicha'
	import * as FileSaver from 'file-saver'
	import * as XLSX from 'xlsx'
	export default {
		data() {
			return {
				queryid:'',
				leixing:'',
				selectleixing: '',
				showpicpage: {
					housepersonlable: [],
					hushu: '',
					huzairenbujingchangzai: '',
					huzairenbuzai: '',
					huzairenzai: '',
					liudongrenkou: '',
					personinfolist: [],
					personnums: '',
					liudonghushu: ''
				},
				wenhuaList: ['博士研究生', '硕士研究生', '大学本科', '大学专科', '高中', '初中', '小学', '学前教育', '其他'],
				xiangqingHouse: false,
				xiangqing: false,
				xiangqing2: false,
				text: '展开',
				show: false,
				chaxun: {
					name: '',
					date1: '',
					date2: [],
					code: '',
					address: '',
					command: "出生年月",
					shequ: "",
					sex: "",
					eduid: "",
					tel: "",
					local: "",
					commandDz: "平台地址",
					censusaddrname: '',
					houseareaname: '',
					incommunity: '',
					label: [],
					syshousearea: ''
				},
				radio: '0',
				duoxuanList: [],
				checkList: [],
				loadingHouse: false,
				options: [{
					value: '',
					label: '全部'
				}, {
					value: '男',
					label: '男'
				}, {
					value: '女',
					label: '女'
				}],
				agegroup: {
					person1: 25,
					person2: 33,
					person3: 100,
					person4: 33,
					person5: 22,
					total: 0,
					height1: 0,
					height2: 0,
					height3: 0,
					height4: 0,
					height5: 0
				},
				list: [],
				time: new Date(),
				treeId: '',
				searchName: '',
				search: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				form: {
					name: '',
					status: '',
					remark: ''
				},
				defaultProps: {
					children: 'list',
					label: 'name'
				},
				treeSelectId: '',
				tree: [],
				newbutton: false,
				levelcode: '',
				xiugaiVisible: false,
				form2: {
					level: '',
					name: '',
					type: '',
					remark: '',
					id: ''
				},
				xiugaiLevelcode: '',
				loading1: true,
				loading2: true,
				mapVisible: false,
				form3: {
					netLocation: '',
					centerLocation: ''
				},
				//网格坐标相关
				house_code: '',
				jichu: {
					mulpersoninfp: {
						personname: '',
						person_sex: '',
						personcard: '',
						houseareatemp: '',
						censusaddr: '',
						personphone: '',
						deldesc: '',
						communityname: '',
						regionname: '',
						party: '',
						housearea: '',
					},
					labelinfo: [],
					personLabel: [{
						name: '',
						labellist: [{
							history: false,
							labelname: ''
						}]
					}]
				},
				historyData: [],
				fenye2: {
					pagesizes: [5, 10, 15, 20, 25, 30],
					pagesize: 10,
					totalnum: 9
				},
				currentPage2: 1,
				housemsg: {},
				masterlist: [],
				personlist: [],
				showType: 1,
				lableTreeData: [],
				labelTreeProps: {
					label: 'labelName',
					children: 'children'
				},
				netTreeData: [],
				netTreeProps: {
					label: 'labelName',
					children: 'children'
				},
				activeName: 'community',
				itemId: null,
				labelId: null,
				dialogFormVisible: false,
				formLabelWidth: '120px',
				fileInfo: null,
				net_id: null,
				tableData2: [],
				labels: [],
				selectTagName: '',
				xiangqing10: false,
				list10: {
					queryid: '',
					personname: '',
					personcard: '',
					personphone: ''
				},
				loading10: false,

			};
		},
		mounted() {
			let that = this;
			that.getTree();
			that.getList();
			that.agegroup.total = that.agegroup.person1 + that.agegroup.person2 + that.agegroup.person3 + that.agegroup
				.person4 + that.agegroup.person5;
			that.agegroup.height1 = (that.agegroup.person1 / that.agegroup.total) * 120;
			that.agegroup.height2 = (that.agegroup.person2 / that.agegroup.total) * 120;
			that.agegroup.height3 = (that.agegroup.person3 / that.agegroup.total) * 120;
			that.agegroup.height4 = (that.agegroup.person4 / that.agegroup.total) * 120;
			that.agegroup.height5 = (that.agegroup.person5 / that.agegroup.total) * 120;
			getUserLevelcode({
				'x-access-token': that.GLOBAL.token(),
				userid: that.GLOBAL.adminId()
			}).then((res) => {
				if (res.result == '200') {
					that.list = res.detail;
				} else {
					that.$message.error(res.description);
				}
			})

			this.getLabels();
		},
		methods: {
			isValidIDCard(idCard) {
				// 18位身份证号码的正则表达式
				let pattern = /^[1-9]\d{5}(19|20)\d{2}(0\d|1[0-2])([0-2]\d|3[0-1])\d{3}[\dxX]$/;
				// 验证身份证号码是否符合要求
				return pattern.test(idCard);
			},
			xiugai(a) {
				let that = this;
				that.list10 = {
					queryid: a.id,
					personname: a.personname,
					personcard: a.personcard,
					personphone: a.personphone
				}
				that.xiangqing10 = true;
			},
			handleClose10: function(done) {
				this.close10();
				done();
			},
			close10() {
				this.xiangqing10 = false;
				this.list10 = {
					queryid: '',
					personname: '',
					personcard: '',
					personphone: ''
				};
			},
			onSubmit10() {
				let that = this;
				if(!that.list10.personname){
					that.$message.warning('请填写姓名');
					return;
				}
				if (!that.isValidIDCard(that.list10.personcard)) {
					that.$message.warning('请填写正确的身份证号');
					return;
				}
				if(!that.list10.personphone){
					that.$message.warning('请填写手机号');
					return;
				}
				that.loading10 = true;
				PersonUpdate({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					queryid:that.list10.queryid,
					personcard:that.list10.personcard,
					personname:that.list10.personname,
					personphone:that.list10.personphone,
				}).then((res) => {
					that.loading10 = false;
					if (res.result == '200') {
						that.$message.success('修改成功');
						that.close10();
						that.lookClick(that.queryid,'ziji');
						that.getList();
					} else {
						that.$message.error(res.description);
					}
				})
			},
			highTag(tagname) {
				if (this.selectTagName == tagname) {
					this.selectTagName = '';
				} else {
					this.selectTagName = tagname;
				}

			},
			xuanze(a) {
				if (this.selectleixing === '') {
					this.selectleixing = a;
				} else {
					this.selectleixing = '';
				}
			},
			getLabels() {
				LableQuery({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					type: 1
				}).then((res) => {
					if (res.result == '200') {
						// console.log(res)
						let labels = res.detail.label;
						labels.map(item => {
							item.label = item.type_name;
							item.value = item.id;
							if (item.labelitem && item.labelitem.length > 0) {
								item.children = item.labelitem;
								item.children.map(child => {
									child.label = child.label_name;
									child.value = child.itemid;
								})
							}
						})
						this.labels = labels;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			getTextOfVal(val) {
				if (val === 0) {
					return '户在人在'
				} else if (val === 1) {
					return '户在人不经常在'
				} else if (val === 2) {
					return '流动人口'
				} else if (val === 3) {
					return '户在人不在'
				} else {
					return '/'
				}
			},
			handleExport() {
				var that = this;

				if (this.activeName != 'net') {
					this.net_id = null
				}
				let data = {
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					flags: this.checkList.length != 0 ? this.checkList.join(',') : "",
					personname: this.chaxun.name,
					idcard: this.chaxun.code,
					sex: this.chaxun.sex,
					eduid: this.chaxun.eduid,
					phone: this.chaxun.tel,
					levelcode: this.chaxun.shequ,
					orand: this.radio,
					house_code: this.house_code,
					gridid: this.net_id,
					houseareaname: this.chaxun.houseareaname,
					censusaddrname: this.chaxun.censusaddrname,
					incommunity: this.chaxun.incommunity,
					syshousearea: this.chaxun.syshousearea,
				}
				if (this.chaxun.command == "出生年月") {
					if (this.chaxun.date1) {
						let bir = this.chaxun.date1.split("-")
						data.birthday = bir[0] + bir[1]
					} else {
						data.birthday = ""
					}
				} else if (this.chaxun.date2 && this.chaxun.date2.length != 0) {
					let start = this.chaxun.date2[0].split("-")
					let end = this.chaxun.date2[1].split("-")
					data.startday = start[0] + start[1] + start[2]
					data.endday = end[0] + end[1] + end[2]
				}
				if (this.activeName == 'label') {
					data.labelid = this.labelId;
					data.itemid = this.itemId;
				} else {
					data.labelid = null;
					data.itemid = null;
				}

				ExportbasePersonlist(data).then((res) => {
					if (res.result == '200') {
						// console.log(res);
						FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);

					} else {
						that.$message.error(res.description);
					}
				})
				this.$nextTick(function() {

				});
			},
			//人员流动流水
			personFlow(){
				this.$router.push({
					path: '/shaicha/personflow'
				})
			},
			relation(a) {
				let that = this;
				if (a) {
					that.xiangqing2 = true;
					indexPeronRelation({
						'x-access-token': that.GLOBAL.token(),
						"userid": that.GLOBAL.adminId(),
						'housecode': a
					}).then((res) => {
						if (res.result == '200') {
							let detail = res.detail;
							let personlist = detail ? detail.personlist : [];
							let guanxilist = detail ? detail.guanxilist : [];
							if (detail) {
								that.openechart(personlist, guanxilist);
							} else {
								that.openechart([], []);
								that.$message.warning('当前暂无关系数据');
							}
						} else {
							that.$message.error(res.description);
						}
					})
				} else {
					that.$message.warning('当前人员暂无居住地址');
				}

			},
			openechart(list1, list2) {
				let that = this;
				let randomArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e'];
				let myChart = echarts.init(document.getElementById('box'));
				let personlist = [];
				let guanxilist = [];
				// let categories = [{
				// 	name: 'person',
				// 	//symbol: 'rect',
				// 	//symbol: 'circle',
				// 	// symbol: 'roundRect',
				// 	// symbol: 'triangle',
				// 	// symbol: 'pin',
				// 	// symbol: 'arrow',
				// 	symbol: 'circle',
				// 	itemStyle: {
				// 		normal: {
				// 			color: "#4592FF",
				// 		}
				// 	},

				// }];
				let categories = [];
				let renlist = JSON.parse(JSON.stringify(list1));
				let unique = (arr) => {
					for (let i = 0; i < arr.length; i++) {
						for (let j = i + 1; j < arr.length; j++) {
							if (arr[i].accountcode == arr[j].accountcode) {
								arr.splice(j, 1)
								j--
							}
						}
					}
					return arr
				}
				let typelist = unique(renlist);
				for (let i = 0; i < list1.length; i++) {
					for (let j = 0; j < typelist.length; j++) {
						if (list1[i].accountcode == typelist[j].accountcode) {
							list1[i].type = j + '' + list1[i].incommunity;
						}
					}
				}
				for (let i = 0; i < typelist.length; i++) {
					let color = '';
					for (var j = 0; j < 6; j++) {
						color += randomArr[Math.ceil(Math.random() * (14 - 0) + 0)];
					}
					color = '#' + color;
					categories.push({
						name: i + '0',
						symbol: 'circle',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
					categories.push({
						name: i + '1',
						symbol: 'rect',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
					categories.push({
						name: i + '2',
						symbol: 'triangle',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
				}

				for (let item of list1) {
					personlist.push({
						name: item.showname, //节点名称
						category: item.type, //节点类型
						draggable: true, //节点是否可拖动
						detail: item
					})
				}
				for (let item of list2) {
					guanxilist.push({
						source: item[1], //关系的起点
						target: item[0], //关系的终点
						value: item[2]
					})
				}
				// 绘制图表
				let option = {
					// title: {
					// 	text: '项目经费',
					// 	top: '12%',
					// 	left: 'center'
					// },
					label: {
						normal: {
							show: true
						}
					},
					series: [{
						edgeSymbol: ["circle", "arrow"],
						edgeSymbolSize: 8,
						edgeLabel: {
							normal: {
								show: true,
								textStyle: {
									fontSize: 12,
								},
								formatter: function(params) {

									return params.data.value;
								},
							},
						},
						type: 'graph', //关系图
						layout: 'force', //力导向图的布局
						symbolSize: 50, //节点大小
						// focusNodeAdjacency: true, //鼠标悬停在节点上时，会隐藏和当前节点非直接连接的节点
						roam: true,
						categories: categories,
						label: { //节点名称
							normal: {
								show: true,
								textStyle: {
									fontSize: 12 //节点名称显示大小
								},
							}
						},
						force: {
							repulsion: 600 //节点之间的排斥力
						},
						data: personlist,
						links: guanxilist,
						lineStyle: { //关系连接线的样式设置
							normal: {
								opacity: 0.9, //关系连接线的不透明度为0.9
								width: 3, //关系连接线的宽度
								curveness: 0 //关系连接线的弯曲程度
							}
						}
					}]
				};
				myChart.setOption(option);
			},
			handleClose2: function(done) {
				done();
			},
			//取消导入
			cancleImport() {
				this.dialogFormVisible = false;
				this.$refs.upload.clearFiles();
			},
			//确定导入人员信息文件
			sureImport() {
				// console.log(this.fileInfo)
				if (!this.fileInfo) {
					this.$message.warning('请选择导入的文件');
					return false;
				}
				let formData = new FormData();
				formData.append("userid", this.GLOBAL.adminId());
				formData.append("file", this.fileInfo);
				let shuju = {
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}
				personImportData(formData, shuju).then((res) => {
					if (res.result == 200) {
						this.$message({
							message: "导入成功",
							type: "success",
						});
						this.dialogFormVisible = false;
						this.$refs.upload.clearFiles();
						this.onsearch();
						setTimeout(() => {
							this.$router.go(0);
						}, 1500)
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件`);
			},
			handleChange(file) {
				this.fileInfo = file.raw;
			},
			importPersons() {
				this.dialogFormVisible = true;
			},
			//下载模版
			downLoadModel() {
				personDownexcel({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					"filename": 'persondata.xlsx'
				}).then((res) => {
					const content = res
					const blob = new Blob([content]) //构造一个blob对象来处理数据
					var a = new Date();
					var year = a.getFullYear();
					var mon = a.getMonth() + 1;
					var month = mon > 9 ? mon : '0' + mon;
					var day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
					var hour = a.getHours() > 9 ? a.getHours() : '0' + a.getHours();
					var min = a.getMinutes() > 9 ? a.getMinutes() : '0' + a.getMinutes();
					var s = a.getSeconds() > 9 ? a.getSeconds() : '0' + a.getSeconds();
					var time = year + '' + month + '' + day + '' + hour + '' + min + '' + s;
					const fileName = '人员信息' + time + '.xls';
					const link = document.createElement('a') //创建a标签
					link.download = fileName //a标签添加属性
					link.style.display = 'none'
					link.href = URL.createObjectURL(blob)
					document.body.appendChild(link)
					link.click() //执行下载
					URL.revokeObjectURL(link.href) //释放url
					document.body.removeChild(link) //释放标签
				})
			},
			//人员删除
			deleteFn(row) {
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					personDelete({
						queryid: row.id
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功');
							this.onsearch();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(() => {

				});
			},
			//查看同住人员信息
			lookHouseInManClick(a) {
				var that = this;
				that.loadingHouse = true;
				houseinfolist({
					'x-access-token': that.GLOBAL.token(),
					"userid": that.GLOBAL.adminId(),
					'houseid': a,
					'page': 1,
					'pagesize': ''
				}).then((res) => {
					if (res.result == '200') {
						that.loadingHouse = false;
						that.housemsg = res.detail.houselist.list[0];
						that.masterlist = res.detail.masterlist;
						that.personlist = res.detail.personlist;
						let length = res.detail.showpicpage.personinfolist.length - 1;
						let num = res.detail.showpicpage.personinfolist[length][0].accountcode;
						// let personinfolist = [];
						// for (let i = 0; i <= num; i++) {
						// 	personinfolist.push({
						// 		num: i,
						// 		list: []
						// 	})
						// 	for (let item of res.detail.showpicpage.personinfolist) {
						// 		if (item[0].accountcode == i) {
						// 			personinfolist[i].list = item
						// 		}
						// 	}

						// }
						that.showpicpage = res.detail.showpicpage;
						// that.showpicpage.personinfolist = personinfolist;
						that.xiangqingHouse = true;
					} else {
						that.$message.error(res.description);
					}
				})

			},
			lookClick(a,leixing) {
				var that = this;
				that.queryid = a;
				that.leixing = leixing;
				personInfo({
					'x-access-token': that.GLOBAL.token(),
					"userid": that.GLOBAL.adminId(),
					queryid: a,
				}).then((res) => {
					if (res.result == '200') {
						that.jichu = res.detail;
						that.xiangqing = true;
					} else {
						that.$message.error(res.description);
					}
				})

			},
			handleCloseHouse: function(done) {
				this.xiangqingHouse = false;
				this.selectleixing = '';
				done();
			},
			handleClose: function(done) {
				this.historyData = [];
				this.jichu = {
					mulpersoninfp: {
						personname: '',
						person_sex: '',
						personcard: '',
						houseareatemp: '',
						censusaddr: '',
						personphone: '',
						deldesc: '',
						communityname: '',
						regionname: '',
						party: ''
					},
					personLabel: [{
						name: '',
						labellist: [{
							history: false,
							labelname: ''
						}]
					}]
				};
				done();
			},
			zhankai() {
				var that = this;
				if (that.show == false) {
					that.show = true;
					that.text = "收起";
				} else {
					that.show = false;
					that.text = "展开";
				}
			},
			// nodeClick() {
			// 	let node = this.$refs.tree.getCurrentNode()
			// 	this.$emit("treeClick", node.name, node.id)
			// },
			handleCommand(command) {
				this.chaxun.command = command
				this.chaxun.date2 = [];
				this.chaxun.date1 = ""
			},
			handleCommandDz(command) {
				this.chaxun.commandDz = command
			},
			getTree() {
				this.loading1 = true;
				searchPersonNumsTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						let tree = res.detail.tree;
						for (let item1 of tree) {
							let num1 = item1.showpersonnums == undefined ? '' : '(' + item1.showpersonnums + ')';
							item1.name += num1;
							item1.list = item1.regionlist;
							for (let item2 of item1.list) {
								let num2 = item2.showpersonnums == undefined ? '' : '(' + item2.showpersonnums +
									')';
								item2.name = item2.regionname + num2;
								item2.list = item2.buildlist;
								for (let item3 of item2.list) {
									let num3 = item3.showpersonnums == undefined ? '' : '(' + item3
										.showpersonnums + ')';
									item3.name = item3.buildname + num3;
									if (item3.housedanyuan) {
										item3.list = item3.housedanyuan;
										for (let item4 of item3.list) {
											let num4 = item4.showpersonnums == undefined ? '' : '(' + item4
												.showpersonnums + ')';
											item4.name = item4.housenum + num4;

										}
									}
								}
							}
						}
						this.tree = tree;
					} else {
						this.$message.error(res.description);
					}
				})

				//标签树
				labelTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == '200') {
						// console.log(res)
						let treeData = res.detail;
						treeData.map(item => {
							item.labelName = item.typeName + `(${item.personCount})`;
							item.children.map(child => {
								child.labelName = child.labelName + `(${child.personCount})`;
							})
						})
						this.lableTreeData = treeData;
					} else {
						this.$message.error(res.description);
					}
				})

				//网格树
				netTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == '200') {
						// console.log(res)
						let treeData = res.detail.tree;
						treeData.map(item => {
							item.labelName = item.name + `(${item.showpersonnums})`;
							item.gridlist.map(child => {
								child.labelName = child.grid_name + `(${child.gridpersonnums})`;
							})
						})
						this.netTreeData = treeData;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//网络树点击
			netTreeNodeClick(data, node) {
				if (node.level == 2) {
					this.net_id = node.data.id;
				}
				this.labelId = null;
				this.itemId = null;
				this.house_code = null;

				this.tableData = [];
				this.currentPage = 1;
				this.getList();
			},
			//网格异步加载树结构
			netLoadNode(node, resolve) {
				if (node.level == 1) {
					return resolve(node.data.gridlist);
				} else {
					return resolve([])
				}
			},
			//展示时要给第一、二标签赋值
			expandClick(data, node) {
				if (node.level == 1) {
					this.labelId = data.id;
					this.itemId = null;
					this.house_code = null;
				} else if (node.level == 2) {
					this.itemId = data.id;
					this.house_code = null;
				}
			},
			//标签树点击
			lableTreeNodeClick(data, node) {
				// console.log(data)
				if (node.level == 1) {
					this.labelId = data.id;
					this.itemId = null;
					this.house_code = null;
				} else if (node.level == 2) {
					this.itemId = data.id;
					this.house_code = null;
				} else {
					this.house_code = data.house_code;
				}

				this.tableData = [];
				this.currentPage = 1;
				this.getList();
			},
			//异步加载树结构
			loadNode(node, resolve) {
				// console.log(node, 'node---')
				if (node.level == 1) {
					return resolve(node.data.children);
				}else if (node.level == 2) {
					this.getSecondTreeList(node.data, resolve);
				}else if(node.level == 4) {
					this.searchBuildTreeByItemFn(node.data, resolve);
				}else if(node.level == 5) {
					this.searchDanyuanTreeByItemFn(node.data, resolve);
				}else{
					if (node.data.children) {
						return resolve(node.data.children)
					}else {
						return resolve([])
					}
				}
			},
			//点击标签树
			getSecondTreeList(data, resolve) {
				// console.log(data)
				searchPersonNumsTreeByItem({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					itemid: data.id,
				}).then((res) => {
					if (res.result == '200') {
						let tree = res.detail;
						// console.log(tree)
						for (let item1 of tree) {
							let num1 = item1.showpersonnums == undefined ? '' : '(' + item1.showpersonnums + ')';
							item1.labelName = item1.name + num1;
							item1.children = item1.regionlist;
							for (let item2 of item1.children) {
								let num2 = item2.showpersonnums == undefined ? '' : '(' + item2.showpersonnums +
									')';
								item2.labelName = item2.regionname + num2;
								/* item2.children = item2.buildlist;
								for (let item3 of item2.children) {
									let num3 = item3.showpersonnums == undefined ? '' : '(' + item3
										.showpersonnums + ')';
									item3.labelName = item3.buildname + num3;
									if (item3.housedanyuan) {
										item3.children = item3.housedanyuan;
										for (let item4 of item3.children) {
											let num4 = item4.showpersonnums == undefined ? '' : '(' + item4
												.showpersonnums + ')';
											item4.labelName = item4.housenum + num4;
										}
									}
								} */
							}
						}
						// console.log(tree)
						return resolve(tree);
					} else {
						this.$message.error(res.description);
					}
				})

			},
			//点击标签树的小区获取楼栋
			searchBuildTreeByItemFn(data, resolve) {
				console.log(data)
				searchBuildTreeByItem({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					itemid: data.itemid,
					regionCode: data.regioncode,
					type: data.type
				}).then((res) => {
					if (res.result == '200') {
						let tree = res.detail;
						// console.log(tree)
						for (let item1 of tree) {
							let num1 = item1.showpersonnums == undefined ? '' : '(' + item1.showpersonnums + ')';
							item1.labelName = item1.buildname + num1;
						}
						// console.log(tree)
						return resolve(tree);
					} else {
						this.$message.error(res.description);
					}
				})

			},
			//点击标签树的楼栋获取单元
			searchDanyuanTreeByItemFn(data, resolve) {
				// console.log(data)
				searchDanyuanTreeByItem({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					itemid: data.itemid,
					regionCode: data.regioncode,
					type: data.type,
					buildId: data.buildid,
					concatcode: data.concatcode
				}).then((res) => {
					if (res.result == '200') {
						let tree = res.detail;
						// console.log(tree)
						for (let item1 of tree) {
							let num1 = item1.showpersonnums == undefined ? '' : '(' + item1.showpersonnums + ')';
							item1.labelName = item1.housenum + num1;
						}
						// console.log(tree)
						return resolve(tree);
					} else {
						this.$message.error(res.description);
					}
				})

			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				// console.log(data);
				var that = this;
				that.tableData = [];
				that.currentPage = 1;
				that.house_code = data.house_code;
				that.getList();
			},
			onsearch() {
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;

				if (this.activeName != 'net') {
					this.net_id = null
				}
				let data = {
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					'page': this.currentPage,
					'pagesize': this.fenye.pagesize,
					flags: this.checkList.length != 0 ? this.checkList.join(',') : "",
					personname: this.chaxun.name,
					idcard: this.chaxun.code,
					sex: this.chaxun.sex,
					eduid: this.chaxun.eduid,
					phone: this.chaxun.tel,
					levelcode: this.chaxun.shequ,
					orand: this.radio,
					house_code: this.house_code,
					gridid: this.net_id,
					houseareaname: this.chaxun.houseareaname,
					censusaddrname: this.chaxun.censusaddrname,
					incommunity: this.chaxun.incommunity,
					label: this.chaxun.label.join(','),
					syshousearea: this.chaxun.syshousearea,
				}
				if (this.chaxun.command == "出生年月") {
					if (this.chaxun.date1) {
						let bir = this.chaxun.date1.split("-")
						data.birthday = bir[0] + bir[1]
					} else {
						data.birthday = ""
					}
				} else if (this.chaxun.date2 && this.chaxun.date2.length != 0) {
					let start = this.chaxun.date2[0].split("-")
					let end = this.chaxun.date2[1].split("-")
					data.startday = start[0] + start[1] + start[2]
					data.endday = end[0] + end[1] + end[2]
				}
				if (this.activeName == 'label') {
					data.labelid = this.labelId;
					data.itemid = this.itemId;
				} else {
					data.labelid = null;
					data.itemid = null;
				}

				basePersonlist(data).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.personpage.list;
						that.fenye.totalnum = res.detail.personpage.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.tishi {
		position: absolute;
		top: 0;
		left: 15px;
		font-size: 15px;
		height: 30px;
		line-height: 30px;

		.type2 {
			padding-left: 30px;
			background-size: 24px 24px;
			background-repeat: no-repeat;
			background-position: 0px 3px;
		}

		.circle {
			background-image: url(../../assets/imgs/circle.png);
		}

		.rect {
			background-image: url(../../assets/imgs/rect.png);
		}

		.triangle {
			background-image: url(../../assets/imgs/triangle.png);
		}
	}

	.right .transformer {
		box-sizing: border-box;
		height: 100%;
		overflow-y: auto;
	}

	.topbox {
		height: 167px;
		margin-bottom: 28px;
		display: flex;
		justify-content: space-around;

		.itembox {
			background-color: #f8f7f7;
			height: 167px;
			width: calc(50% - 7px);
			position: relative;
			box-sizing: border-box;
			display: flex;
			justify-content: center;

			.itemleft {
				display: flex;
				justify-content: center;
				width: 40%;

				div {
					box-sizing: border-box;
					padding-top: 32px;

					img {
						display: block;
						width: 53px;
						height: 90px;
						margin: auto;
					}

					p {
						line-height: 34px;
						color: #282828;
						font-size: 12px;
						text-align: center;

						span {
							font-size: 16px;

						}
					}
				}
			}

			.itemright {
				display: flex;
				width: 60%;
				box-sizing: border-box;
				height: 167px;
				align-items: center;

				div {
					box-sizing: border-box;
					width: 50%;
					height: 73px;
					text-align: center;
					display: flex;
					justify-content: center;
					flex-direction: column;

					.name {
						line-height: 24px;
						font-size: 16px;
						color: #757575;
					}

					.num {
						line-height: 30px;
						color: #090909;
						font-size: 16px;

						span {
							font-weight: 700;
							font-size: 22px;
						}
					}
				}
			}

			.title {
				position: absolute;
				left: 0;
				top: 0;
				width: 84px;
				height: 56px;
				line-height: 56px;
				text-align: center;
				font-size: 16px;
				color: #409eff;
			}

			.box {
				box-sizing: border-box;
				padding-top: 11px;
				margin: 0 5px;

				.zhuzi {
					width: 26px;
					height: 120px;
					overflow: hidden;
					margin: 0 auto 2px;
					background-color: #edecf3;
					border-radius: 4px;
					position: relative;

					.percent {
						border-radius: 4px;
						position: absolute;
						left: 0;
						bottom: 0;
						width: 26px;

						p {
							top: 0;
						}
					}

					p {
						line-height: 20px;
						font-weight: 700;
						font-size: 12px;
						color: #282828;
						position: absolute;
						left: 0;
						width: 100%;
					}

					.percent1 {
						background-color: #edb700;
					}

					.percent2 {
						background-color: #a3c827;
					}

					.percent3 {
						background-color: #dd6a6a;
					}

					.percent4 {
						background-color: #46b7eb;
					}

					.percent5 {
						background-color: #ae7e84;
					}
				}

				.type1 {}

				img {
					width: 54px;
					height: 9px;
					margin: 0 auto;
					display: block;
				}

				p {
					text-align: center;
					line-height: 22px;
					font-size: 12px;
					color: #757575;

				}
			}
		}

	}

	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			// border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: '+';
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: '-';
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;
		}
	}



	.form3style {
		padding: 0 20px;

		.el-form-item {
			.spanstyle {
				padding-left: 25px;
				font-size: 20px;
				background: url(../../assets/imgs/edit.png) no-repeat left center;
				background-size: 20px auto;
				color: #5B93C1;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.detailtable {
		overflow: hidden;
		border-top: 1px solid #e3e4e3;
		border-left: 1px solid #e3e4e3;
		margin-bottom: 20px;

		.biaoqian {
			width: 100%;
			height: auto !important;
			padding: 5px 10px;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					margin: 2px 4px 2px 0;
					color: #2a8cf1;
					border: 1px solid #2a8cf1;
					border-radius: 100px;
					padding: 2px 8px;
					line-height: 1.2em;
					font-size: 14px;
				}
			}

		}
	}

	.detailtable p,
	.detailtable div {
		float: left;
		box-sizing: border-box;
		border-right: 1px solid #e3e4e3;
		border-bottom: 1px solid #e3e4e3;

	}

	.detailtable p {
		height: 37px;
		line-height: 37px;
		background-color: #e1f3fd;
		width: 100%;
		margin: 0;
		padding-left: 8px;
		color: #5b93c1;
		font-size: 16px;

	}

	.detailtable div {
		height: 36px;
		line-height: 36px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.detailtable .labelname {
		width: 8%;
	}

	.detailtable .biaoshi {
		text-align: left;

	}

	.produce {
		overflow: hidden;
		box-sizing: border-box;
		border-top: 1px solid #666;
		border-left: 1px solid #666;
		margin-top: 20px;

		.biaoqian {
			width: 100%;
			height: auto !important;
			padding: 5px 10px;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					margin: 2px 4px 2px 0;
					color: #2a8cf1;
					border: 1px solid #2a8cf1;
					border-radius: 100px;
					padding: 2px 8px;
					line-height: 1.2em;
					font-size: 14px;
				}
			}

		}
	}

	.produce>div {
		float: left;
		width: 50%;
		height: 41px;
		box-sizing: border-box;
		border-bottom: 1px solid #666;
		border-right: 1px solid #666;
		line-height: 40px;
		padding: 0 10px;
	}

	.create {
		.toubu {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				font-size: 18px;
				color: #1e9fff;
				// margin: 3px 0;
			}
		}
	}

	.personsbox {
		display: flex;

		.huzhuinfo {
			width: 36%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			&::after,
			&::before {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border: 10px solid #fff;
				border-right-color: #119CF0;
				right: -1px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 5;
			}

			&::before {
				border: 8px solid transparent;
				border-right-color: #fff;
				z-index: 10;
			}
		}

		.fz_item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.head {
				margin-bottom: 40px;
				width: 90px;
				height: 90px;
				text-align: center;
				line-height: 90px;
				position: relative;
				background-color: #f0f0f0;
				border-radius: 50%;

				.iconsex {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 80%;
						width: auto;
					}
				}

				i {
					font-size: 50px;
					line-height: 90px;
				}

				>img {
					position: absolute;
					bottom: -20px;
					left: 50%;
					transform: translateX(-50%);
					width: auto;
					height: 40px;
				}

				&+div {
					font-size: 14px;
					color: #333;
				}
			}

			ul {
				li {
					line-height: 1.6em;

					span {
						width: 90px;
						text-align: right;
						display: inline-block;
					}
				}
			}
		}

		.zhuhuinfo {
			margin-top: 20px;
			padding: 20px 0 20px 100px;
			border-left: 1px solid #119CF0;
			position: relative;

			&::before,
			&::after {
				width: 50px;
				height: 1px;
				content: "";
				background-color: #119CF0;
				position: absolute;
				left: 0;
			}

			&::before {
				top: 0;
			}

			&::after {
				bottom: 0;
			}

			.zh_item {
				position: relative;

				&:last-child {
					border-bottom: 0 none;
				}

				.head {
					width: 60px;
					height: 60px;
					text-align: center;
					line-height: 60px;
					position: absolute;
					left: - 20px;
					top: 50%;
					transform: translateY(-90%);
					background-color: #f0f0f0;
					border-radius: 50%;

					.iconsex {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							height: 80%;
							width: auto;
						}
					}

					i {
						font-size: 20px;
						line-height: 60px;
					}

					p {
						position: absolute;
						font-size: 14px;
						width: 80px;
						text-align: center;
						color: #333;
						left: 50%;
						line-height: 1.6em;
						border: 1px solid #119CF0;
						transform: translateX(-50%);
						bottom: -30px;
						background-color: #fff;
						border-radius: 6px;
					}
				}

				ul {
					margin: 20px 0;
					padding: 25px 50px 25px 30px;
					background-color: #fff;
					border: 1px solid #119CF0;

					li {
						line-height: 1.6em;
						font-size: 14px;

						span {
							width: 120px;
							text-align: right;
							display: inline-block;
						}
					}
				}
			}


		}
	}


	.block {
		padding: 20px 0 10px;
	}

	.totaldata {
		line-height: 2em;
		font-weight: bold;
		color: #409EFF;
	}

	.allpeople {
		overflow: hidden;
		box-sizing: border-box;
		border: 1px solid #e8e9e8;
		padding: 0 22px;

		>div:first-child {
			border-top: none !important;
		}

		.yihu {
			border-top: 1px solid #d2d2d2;
			padding: 28px 0 0;
			position: relative;
			padding-left: 120px;
			display: flex;
			flex-wrap: wrap;
			min-height: 100px;

			.title {
				text-align: center;
				font-size: 12px;
				color: #fff;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 90px;
				height: 90px;

				img {
					width: 50px;
					height: auto;
				}

				p {
					margin-top: 10px;
					color: #333;
				}
			}

			>.people:first-child {
				border-left: none !important;
			}

			.people {
				border-left: 1px dotted #e5e5e5;
				padding: 0 20px;
				margin-bottom: 28px;

				.head {
					display: inline-flex;
					flex-wrap: wrap;
					writing-mode: vertical-lr;
					position: relative;

					.img {
						overflow: hidden;
						width: 90px;
						height: 90px;
						margin: 0 10px;
						border-radius: 50%;
						cursor: pointer;

						img {
							display: block;
							width: 100%;
							height: auto;
						}
					}

					.huise {
						background-color: #e8e9e8;
					}

					.lanse {
						background-color: #259cef;
					}

					p {
						line-height: 28px;
						text-align: center;
						font-size: 16px;
						color: #878787;
						width: 96px;
						writing-mode: horizontal-tb;
					}

					.tag {
						box-sizing: border-box;
						writing-mode: horizontal-tb;
						border-radius: 200px;
						margin: 2px 5px;
						border: 1px solid #666;
						height: 20px;
						line-height: 18px;
						padding: 0 5px;
						background-color: #fff;
						color: #666;
						font-size: 14px;
						position: absolute;
						right: 0;
						transform: translateX(60%);
						top: -10px;

						&.blue {
							background-color: #409eff;
							border: 1px solid #409eff;
							color: #fff;

						}
					}
				}
			}
		}
	}

	.alldata {
		.hang {
			display: flex;
			flex-wrap: wrap;

			div {
				margin: 0 18px 10px 0;
				height: 30px;
				line-height: 30px;
				border-radius: 500px;
				padding: 0 15px;
				font-size: 16px;
			}

		}

		.one {
			div {
				background-color: #00a0e9;
				color: #fff;
			}
		}

		.two {
			div {
				background-color: #e5f3ff;
				color: #48a8d7;
				cursor: pointer;
			}

			.selected {
				background-color: #00a0e9;
				color: #fff;
			}
		}

		.three {
			display: flex;
			justify-content: end;
			cursor: pointer;

			div {
				box-sizing: border-box;
				border: 1px solid #666;
				background-color: #fff;
				color: #666;
				line-height: 28px !important;
			}

			.highblue {
				background-color: #00a0e9;
				color: #fff;
			}
		}
	}
</style>